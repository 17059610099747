export const environment = {
  production: true,
  baseUrl: "https://vis-api.friskus.com/api/v1",
  googleMapKey: "AIzaSyDltOmOTBCiHBRlKfeukaziheQ_fuBHUhA",
  uploadCareKey: "89c8f3b9a267f0426d6a",
  activityTypeVolunteerUUID: "7a0e58cc-c204-4a12-a7f4-fe5fc405c7f9",
  isLangRedirectEnabled: true,
  apiUrl: {
    rss: 'https://vis-api.friskus.com/feed',
    event: 'https://vis-api.friskus.com/api/v1',
    group: 'https://vis-api.friskus.com/api/v1',
    identity: 'https://vis-api.friskus.com/api/v1',
    user: 'https://vis-api.friskus.com/api/v1',
    messenger: 'https://vis-api.friskus.com/api/v1',
    card: 'https://vis-api.friskus.com/api/v1',
    cardV2: 'https://vis-api.friskus.com/api/v2',
    notifications: 'https://vis-api.friskus.com/api/v1',
    tickets: 'https://vis-api.friskus.com/api/v1',
  },
  backofficeUrl: "https://vis-api.friskus.com",
  cards: {
    provider_id: "c16d46e3-152d-4280-a622-64dee1f09593",
  },
  bugsnagKey: "029a2770fea541d218b1b2e22362b1cd",
  gtmId: "",
  defaultHasLeisureCard: true,
  hasSubsidiaryOrg: false,
  prefix: "vis",
  news: false,
};
